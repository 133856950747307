import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
// import { Redirect, Link } from 'react-router-dom';
import qs from 'qs';
import { Box, Card, CardContent, Typography, Grid } from '@mui/material';
import compose from 'recompose/compose';
// import { actions as ssoActions } from '../../reducers/sso';
import { types as accountTypes } from '../../reducers/account';
import history from '../../history';

class AuthLogout extends PureComponent {
  constructor(props) {
    super(props);
    const { from, redirect, authOk } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    // if (!(from && token && redirect && authOk)) { // 登录前哪有token?
    if (!(from && redirect)) {
      history.push(
        `/sso/redirect?content=${encodeURIComponent(
          '登出信息不全'
        )}&to=${encodeURIComponent(redirect)}`
      );
    }
    this.state = {
      subTitle: `${from}`,
      redirect,
      from,
      authOk
    };
    // 直接登出然后回redirect
    this.props.dispatch({ type: accountTypes.SAGA_LOGOUT_REQUEST });
  }
  componentDidMount() {
    this.setState({ countDown: 4 });
    this.timer = setInterval(this.count, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }
  count = () => {
    // console.log('this.username :>> ', this.username);
    this.setState(preState => ({ countDown: preState.countDown - 1 }));
  };
  redirect = () => {
    const to = this.state.redirect;
    if (to.startsWith('http://') || to.startsWith('https://')) {
      // 如果是跳外链，等100ms处理干净logout再跳转
      setTimeout(() => {
        global.location = to;
      }, 100);
    } else history.push(to);
  };

  render() {
    const { username } = this.props;
    const { countDown } = this.state;
    // 没有用户信息了就跳回去
    if (!username || countDown === 0) {
      clearInterval(this.timer);
      this.redirect();
    }
    return (
      <Grid
        container
        sx={{
          height: '80%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Grid item>
          <Card sx={{ width: '30rem' }}>
            <CardContent>
              <Box sx={{ m: '2rem 0' }}>
                <Typography align="center" variant="h6">
                  退出中... ({countDown}
                  s)
                </Typography>
                <Typography align="center" color="textSecondary">
                  {this.state.subTitle}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.account.username
  };
}
export default compose(connect(mapStateToProps))(AuthLogout);
