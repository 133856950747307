import React from 'react';
import { styled } from '@mui/material/styles';
import { Route, Switch } from 'react-router-dom';
import compose from 'recompose/compose';
import InfoBasic from './account/InfoBasic';
import Password from './account/Password';
import AuthBind from './sso/AuthBind';
import CDN from './tools/cdn';
import CDNRefresh from './tools/cdnRefresh';
import ImageMin from './tools/imagemin';

const Root = styled('div')(() => ({
  height: 'calc(100% - 50px)',
  width: '100%',
  margin: '0 auto',
  paddingTop: '30px',
  maxWidth: '1200px'
}));

const Main = styled('div')(() => ({
  padding: 20,
  flex: 'auto',
  minHeight: '90%'
}));

class Home extends React.Component {
  render() {
    console.log('render home');
    return (
      <Root>
        <Main>
          <Switch>
            <Route path="/sso/account/info" component={InfoBasic} />
            <Route path="/sso/account/password" component={Password} />
            <Route path="/sso/auth/mybinds" component={AuthBind} />
            <Route path="/tools/cdn" component={CDN} />
            <Route path="/tools/cdnRefresh" component={CDNRefresh} />
            <Route path="/tools/imagemin" component={ImageMin} />
            <Route path="/sso/about" component={null} />
          </Switch>
        </Main>
      </Root>
    );
  }
}

export default compose()(Home);
